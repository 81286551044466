<template>
	<!-- app设置 -->
	<div class="el-content">
		<a-form :label-col="{span:3}" :wrapper-col="{span:12}">
			<a-tabs defaultActiveKey @change="getSetData">
				<a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
					<div v-if="item.key == 1">
						<com-power-form :form-data="[
							{label:'H5分享域名',type:'text',key:'app_domain',value:'',dataType:'string',
								tips:'H5分享域名必须填写;例如:https://farm.com/ , 注意:末尾请加上“/”'
							},
							{label:'登录页标题',type:'text',key:'login_title',value:'',dataType:'string'},
							{label:'是否开启第三方登录',type:'switch',key:'login_open_third',value:'',dataType:'string'},
							{label:'登录页面背景',type:'image',key:'login_bg',value:'',dataType:'string'},
							{label:'下载页面背景',type:'image',key:'download_bg',value:'',dataType:'string'},
							{label:'IOS下载地址',type:'text',key:'download_ios_url',value:'',dataType:'string'},
							{label:'Android下载地址',type:'text',key:'download_android_url',value:'',dataType:'string'},
							{label:'用户协议',type:'editor',key:'user_agreement',value:'',dataType:'string'},
							{label:'用户隐私协议',type:'editor',key:'user_privacy_agreement',value:'',dataType:'string'},
							{label:'APP首次打开提示',type:'editor',key:'app_first_open_agreement',value:'',dataType:'string'},
						]"></com-power-form>
					</div>
					<div v-if="item.key == 2">
						<com-power-form :form-data="[
							{label:'APPID',type:'text',key:'app_appid',value:'',dataType:'string'},
							{label:'当前版本号',type:'text',key:'app_current_version',value:'',dataType:'string',
								tips:'当前版本号与打包的wgt更新包的版本号必须一致,如果打包的版本号是1.0.1 ,则填写101,必须为整数'
							},
							{label:'APP热更新wgt包地址',type:'upload-cert',key:'app_update_url',value:'',dataType:'string',hideUrl:1},
						]"></com-power-form>
					</div>
					<div v-if="item.key == 5">
						<com-power-form :form-data="[
							{label:'应用AppID',type:'text',key:'app_appid',value:'',dataType:'string'},
							{label:'应用AppKey',type:'text',key:'app_appkey',value:'',dataType:'string'},
							{label:'应用MasterSecret',type:'text',key:'app_master_secret',value:'',dataType:'string'},
							{label:'应用包名',type:'text',key:'app_pakage_name',value:'',dataType:'string'},
						]"></com-power-form>
					</div>
					<div v-if="item.key == 6">
						<com-power-form form-type="card" :form-data="[
							{title:'V3微信APP(非服务商)',param:[
								{label:'身份标识(appId)',type:'text',key:'wx_app_appid',value:'',dataType:'string'},
								{label:'身份密钥(appSecret)',type:'hide-text',key:'wx_app_appsecert',value:'',dataType:'string'},
								{label:'微信支付商户号',type:'text',key:'wx_app_mchid',value:'',dataType:'string'},
								{label:'微信支付密钥',type:'text',key:'wx_app_paysecert',value:'',dataType:'string'},
								{label:'微信支付证书CERT',type:'upload-cert',key:'wx_app_cert',value:'',dataType:'string'},
								{label:'微信支付证书KEY',type:'upload-cert',key:'wx_app_key',value:'',dataType:'string'},
							]},
							{title:'V3微信APP(服务商)',param:[
								{label:'商户证书序列号',type:'text',key:'wx_app_mch_cert_num',value:'',dataType:'string'},
								{label:'V3API秘钥',type:'text',key:'wx_app_v3vip_key',value:'',dataType:'string'},
								{label:'商户私钥',type:'upload-cert',key:'wx_app_mch_key',value:'',dataType:'string'},
							]}
						]"></com-power-form>
					</div>
					<div v-if="item.key == 7">
						<com-power-form :form-data="[
							{label:'身份标识(appId)',type:'text',key:'pay_alipay_appid',value:'',dataType:'string'},
							{label:'支付宝公钥',type:'textarea',key:'pay_alipay_public_key',value:'',dataType:'string'},
							{label:'商户私钥(merchant_private_key)',type:'textarea',key:'pay_alipay_merchant_private_key',value:'',dataType:'string'},
							{label:'应用根证书',type:'upload-cert',key:'pay_alipay_root_cert',value:'',dataType:'string'},
							{label:'应用公钥证书',type:'upload-cert',key:'pay_alipay_app_public_cert',value:'',dataType:'string'},
							{label:'支付宝公钥证书',type:'upload-cert',key:'pay_alipay_public_cert',value:'',dataType:'string'},
							{label:'商户私钥证书',type:'upload-cert',key:'pay_alipay_merchant_private_cert',value:'',dataType:'string'},
						]"></com-power-form>
					</div>
					<div v-if="item.key == 3">
						<com-power-form :form-data="[
							{label:'应用名称',type:'text',key:'app_package_name',value:'',dataType:'string'},
							{label:'应用描述',type:'text',key:'app_package_desc',value:'',dataType:'string'},
							{label:'APP图标配置(1024*1024)',type:'image',key:'app_package_icon',value:'',dataType:'string'},
							{label:'高德地图appkey_ios',type:'text',key:'app_package_gaode_appkey_ios',value:'',dataType:'string'},
							{label:'微信登录appid',type:'text',key:'app_package_wxchat_appid',value:'',dataType:'string'},
							{label:'微信登录appsecret',type:'text',key:'app_package_wxchat_appsecret',value:'',dataType:'string'},
							{label:'微信登录UniversalLinks',type:'text',key:'app_package_wxchat_links',value:'',dataType:'string'},
							{label:'QQ登录appid',type:'text',key:'app_package_qq_appid',value:'',dataType:'string'},
						]"></com-power-form>
					</div>
					<div v-if="item.key == 4">
						<a-button type="primary" @click="applyPackage">申请打包</a-button>
						<a-table row-key="id" :pagination="false" :data-source="packageData.list" :columns="[
							{title:'ID',dataIndex:'id'},
							{title:'申请时间',dataIndex:'create_time'},
							{title:'申请状态',dataIndex:'status',slots:{customRender:'status'}},
							{title:'下载地址',dataIndex:'file_url',slots:{customRender:'file_url'}},
							{title:'反馈信息',dataIndex:'return_msg'},
						]" class="mt12">
							<template #status="{record}">
								<a-tag color="primary" v-if="record.status ==1 ">申请中</a-tag>
								<a-tag color="success" v-if="record.status ==2 ">申请成功</a-tag>
								<a-tag color="danger" v-if="record.status ==3 ">申请失败</a-tag>
							</template>
							<template #file_url="{record}">
								<div>Android：{{ record.file_url}}</div>
								<div>IOS：{{ record.ios_file_url}}</div>
							</template>
						</a-table>
						<div class="pager">
							<a-pagination
								show-size-changer
								:default-current="packageData.page"
								:total="packageData.count"
								@showSizeChange="(p,e)=>{getPackageList(packageData.page,e)}"
								@change="(e)=>{getPackageList(e,packageData.limit)}"
							/>
						</div>
					</div>
				</a-tab-pane>
			</a-tabs>
		</a-form>
	</div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import set from "@/api/set.js"
import rh from '@/router/routerHandle.js'
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
	components:{
		comPowerForm
	},
	setup(){
		const active = ref("1")
		const _d = reactive({
			tabs:[],
			
			packageData:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
		})
		_d.tabs = rh.getCuurentTabs("appset")
		if( _d.tabs.length > 0 ) getSetData(_d.tabs[0].key)
        
        function getSetData(_a){
            let keys = []
			active.value =_a
            if( _a == 4 ){
				getPackageList(1,10)
				return
			}
        }

		//获取打包申请列表信息
		function getPackageList( page ,limit ){
			set.getAppPackageList(page,limit).then(res=>{
				_d.packageData = { limit,...res}
			})
		}
		//申请APP打包
		function applyPackage(){
			set.submitAppPackage().then(()=>{
				getPackageList(_d.packageData.page,_d.packageData.limit)
			})
		}

		return{
			active,
			...toRefs(_d),
			getPackageList,
			applyPackage,
			getSetData
		}
	}
}
</script>

<style>
</style>
